import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import { Link } from "gatsby"
import css from "../setup.module.scss"
import fontType from "../../helpers/fontType.module.scss"

import TRACKINGCODE_EMAIL from "../../assets/images/setup/setup-trackcode-email.png"
import MAGENTO_TRACKINGCODE from "../../assets/images/setup/setup-magento-paste.png"
import MAGENTO_EMAIL_1 from "../../assets/images/setup/setup-magento12-config.png"
import MAGENTO_EMAIL_2 from "../../assets/images/setup/setup-magento12-bcc.png"

const metadata = {
  title: "Connect Magento to ReferralCandy",
}

const MagentoSetup = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Set Up ReferralCandy for Magento</h1>
        <p className={fontType.b1}>
          Step 1: Setting up your ReferralCandy Account
        </p>
        <ol type="1">
          <li>
            Sign up for ReferralCandy{" "}
            <a href="https://my.referralcandy.com/signup#magento">here</a>.
          </li>
          <li>
            Go through the account setup wizard. At the integration step, note
            the tracking code and email address for your referral program. You
            will need them for the next two parts.
            <br />
            <br />
            <img src={TRACKINGCODE_EMAIL} alt="Tracking Code Email" />
          </li>
          <li>Complete the rest of the setup wizard.</li>
        </ol>
        <p className={fontType.b1}>
          Step 2: Adding the tracking code to your Magento store
        </p>
        <ol type="1">
          <li>Go to your Magento root folder.</li>
          <li>
            From the root folder, find the success.phtml file for the current
            theme and view that your Magento store is using. It should be
            located at:
            <code>
              app/design/frontend/ThemeName/ViewName/template/checkout/success.phtml
            </code>
          </li>
          <li>Open up success.phtml using a text editor.</li>
          <li>
            Scroll down to the bottom of the file and copy and paste the
            javascript tracking code obtained in Step 2 to the end of the file.
            <br />
            <br />
            <img src={MAGENTO_TRACKINGCODE} alt="Magento Tracking Code" />
          </li>
          <li>Save the file and close it.</li>
        </ol>
        <p className={fontType.b1}>
          <p className={fontType.b1}>
            Step 3: Adding the tracking code to your Magento store
          </p>
        </p>
        <ol type="1">
          <li>Log into your Magento Admin Panel.</li>
          <li>
            From the Admin Panel, click "System" (Magento 1.x)/ "Stores"
            (Magento 2.x) in the top navigation toolbar.
          </li>
          <li>
            Select "Configuration" from the drop down menu
            <br />
            <br />
            <img src={MAGENTO_EMAIL_1} alt="Magento Email 1" />
          </li>
          <li>
            In Configuration, click "Sales Emails" in the left navigation
            column.
          </li>
          <li>In the Sales Emails panel, click the "Order" row.</li>
          <li>
            Copy and paste the ReferralCandy email address from Step 2 into the
            "Send Order Email Copy To" input box.
          </li>
          <li>
            In the "Send Order Email Copy Method" row, select "Bcc" from the
            dropdown menu.
            <br />
            <br />
            <img src={MAGENTO_EMAIL_2} alt="Magento Email 2" />
          </li>
          <li>Click "Save Config" to save the changes.</li>
        </ol>
        <h3>
          Using a different platform? <Link to="/setup">View all guides</Link>
        </h3>
      </Grid>
    </Layout>
  )
}

export default MagentoSetup
